
'use strict';
const interval = setInterval(function () {
    if (document.getElementsByClassName('productCarouselItem')) {
        $('.productCarouselItem').on('init', function(event, slick) {
            $('.productCarouselItem').removeClass('d-none');
            $('.load-carrossel').remove();
        }).slick({
            slidesToShow: 4,
            infinite: false,
            slidesToScroll: 4,
            arrows: true,
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',

            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2.3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        dots: true,
                        slidesToShow: 2,
                        vertical:false,
                        slidesToScroll: 1
                    }
                }
            ]
        })


        if (document.getElementsByClassName('sellerCarouselContainer')) {
            $('.sellersCarousel').slick({
                slidesToShow: 6,
                infinite: false,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '<span class="slick-prev"></span>',
                nextArrow: '<span class="slick-next"></span>',

                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            dots: true,
                            slidesToShow: 2.3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 411,
                        settings: {
                            dots: true,
                            slidesToShow: 2.2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 391,
                        settings: {
                            dots: true,
                            slidesToShow: 2.1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 370,
                        settings: {
                            dots: true,
                            slidesToShow: 1.8,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }

        if (document.getElementsByClassName('brandsCarouselContainer')) {
            $('.brandsCarousel').slick({
                slidesToShow: 8,
                infinite: true,
                slidesToScroll: 1,
                arrows: false,

                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1

                        }
                    }
                ]
            });
        }
        if (document.getElementsByClassName('tipBarContainer')) {
            $('.tipBarCarousel').slick({
                slidesToShow: 4,
                infinite: true,
                slidesToScroll: 1,
                arrows: false,

                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            slidesToScroll: 1

                        }
                    }
                ]
            });
        }


        if (document.getElementsByClassName('categoriesGridContainer')) {
            $('.categoriesGridCarouselMobile').slick({
                infinite: false,
                arrows: false,
                dots: false,

                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true,
                            arrows: true,
                            dots: false,
                            prevArrow: '<span class="slick-prev"></span>',
                            nextArrow: '<span class="slick-next"></span>'
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                            arrows: true,
                            dots: false,
                            prevArrow: '<span class="slick-prev"></span>',
                            nextArrow: '<span class="slick-next"></span>'
                        }
                    },
                    {
                        breakpoint: 426,
                        settings: {
                            slidesToShow: 2.1,
                            slidesToScroll: 1,
                            arrows: true,
                            infinite: true,
                            dots: true,
                            prevArrow: '<span class="slick-prev"></span>',
                            nextArrow: '<span class="slick-next"></span>'
                        }
                    },
                    {
                        breakpoint: 398,
                        settings: {
                            slidesToShow: 1.5,
                            slidesToScroll: 1,
                            arrows: true,
                            infinite: true,
                            dots: true,
                            prevArrow: '<span class="slick-prev"></span>',
                            nextArrow: '<span class="slick-next"></span>'
                        }
                    }
                ]
            });
        }

        if (document.getElementById('product-carousel')) {
            $(document.getElementsByTagName('div')).slick({
                slidesToShow: 4,
                infinite: true,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '<span class="slick-prev"></span>',
                nextArrow: '<span class="slick-next"></span>',

                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            slidesToScroll: 1

                        }
                    }
                ]
            });
        }
        $('.productCarouselItem').removeClass('skeleton-loaderCarousel');

        clearInterval(interval);
    }
}, 100);

